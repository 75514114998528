.k-inputradio-container
	&.has-label
		padding-top: 30px

	&.inline
		.radio-item
			display: inline-block

			&:not(:last-child)
				margin-right: 1rem

	.p-float-label label
		top: 0
		left: 0
		margin-top: -1.75rem
		color: $color-gray-darker

	.radio-item
		position: relative
		display: flex
		align-items: center

		&:not(:last-child)
			margin-bottom: .5rem

		label
			position: relative
			margin: 0 0 0 .5rem
			font-family: 'Roboto-Regular', sans-serif
			font-size: 1rem
			color: $color-gray-darker

	// Checked
	.p-radiobutton-checked
		&.p-radiobutton-focused
			.p-radiobutton-box
				box-shadow: none

		.p-radiobutton-box,
		.p-radiobutton-box.p-highlight:not(.p-disabled):hover
			background: $color-white
			border-color: $color-purple-primary

		.p-radiobutton-icon
			background-color: $color-purple-primary

	// Disabled
	.p-radiobutton.p-radiobutton-disabled
		cursor: default

	.p-float-label.is-disabled
		.radio-item
			opacity: 0.65
