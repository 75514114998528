.k-inputswitch-container
	display: flex
	align-items: center

	.k-inputswitch-label
		margin-left: 1rem
		font-family: 'Roboto-Regular', sans-serif
		color: $color-gray-darker

	.p-inputswitch

		// Focused
		&.p-focus .p-inputswitch-slider
			box-shadow: none

		// Checked
		&.p-inputswitch-checked .p-inputswitch-slider
			background: $color-purple-primary

		// Default
		.p-inputswitch-slider
			border-radius: 1rem
			background: $color-gray-light

			// White circle
			&:before
				border-radius: 1rem
