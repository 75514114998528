.engagement-history-container
	height: 100%
	display: flex
	flex-direction: column

	.info-wrapper
		padding-top: 15px
		border-bottom: 1px solid $color-gray-medium
		background-color: $color-white
		overflow-x: hidden

	.list-wrapper
		flex: 1
		overflow: auto
