/**********************************/
/********  WORK SANS  *************/
/**********************************/

@font-face
	font-family: 'WorkSans-Black'
	font-weight: 900
	font-style: normal
	src: url('../fonts/work-sans/WorkSans-Black.ttf')

/**********************************/
/**********  ROBOTO ***************/
/**********************************/


@font-face
	font-family: 'Roboto-Regular'
	font-weight: 400
	font-style: normal
	src: url('../fonts/roboto/Roboto-Regular.ttf')

@font-face
	font-family: 'Roboto-Bold'
	font-weight: 700
	font-style: normal
	src: url('../fonts/roboto/Roboto-Bold.ttf')

@font-face
	font-family: 'Roboto-Black'
	font-weight: 900
	font-style: normal
	src: url('../fonts/roboto/Roboto-Black.ttf')