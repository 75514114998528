.p-inputtext
    color: $color-gray-darker
    border-color: $color-gray-medium
    height: 48px
    width: 100%

    &:enabled:hover
        border-color: $color-gray-medium
        border-width: 2px

    &:enabled:focus
        border-color: $color-purple-primary
        border-width: 2px
        box-shadow: none

    &.p-invalid:enabled:hover,
    &.p-invalid:enabled:focus
        border-color: $color-red-primary
