.p-menu
	&.p-menu-overlay
		padding: 0

	.p-menuitem-link
		.p-menuitem-text
			color: $color-gray-darker
			font-family: 'Roboto-Regular', sans-serif

		&:not(.p-disabled):hover
			background-color: $color-blue-lightest
			.p-menuitem-text
				color: $color-gray-darker
