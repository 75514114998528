.medications-modal

	.medication-create-form
		display: flex
		align-items: center
	
		&__remove
			justify-self: flex-end
			min-width: 42px

			button
				margin-top: 1rem

		.form-section
			margin-top: 1rem
			flex: 1

		.k-input-container
			margin-bottom: 0

	@media (max-width: 768px)
	.medication-create-form
		.k-input-container
			margin-bottom: .5rem


.medications-category-notes
	.update-mode
		width: 100%
		display: flex

		.label
			width: 50px
			margin-right: 10px
		.k-input-container
			flex: 1
		.actions
			width: 150px
			display: flex
			vertical-align: middle
			align-items: center
			margin-left: 10px

	
	.empty-mode
		display: flex
		flex-direction: column
		text-align: center
		justify-content: center
