.k-inputcheckbox-container

	// Checkbox: binary
	&.chbx-binary
		padding-top: 8px

		.p-float-label label
			margin-left: 1rem
			font-family: 'Roboto-Regular', sans-serif
			color: $color-gray-darker

		.p-float-label.is-disabled label
			opacity: 0.65

	// Checkbox: group
	&.chbx-group
		&.has-label
			padding-top: 30px

		.p-float-label label
			top: 0
			left: 0
			margin-top: -1.75rem
			color: $color-gray-darker

		.checkbox-item
			position: relative
			display: flex
			align-items: center

			&:not(:last-child)
				margin-bottom: .5rem

			label
				position: relative
				margin: 0 0 0 .5rem
				font-family: 'Roboto-Regular', sans-serif
				font-size: 1rem
				color: $color-gray-darker

	// Disabled
	.p-checkbox.p-checkbox-disabled
		cursor: default

	.p-float-label.is-disabled
		.checkbox-item
			opacity: 0.65
