$color-white: #ffffff
$color-black: #000000

// Blue
$color-blue-lightest: #EAF1FB
$color-blue-light: #D7E6F0
$color-blue-background: #B3E5FC
$color-blue-foreground: #23547B
$color-blue-medium: #378fcb
$color-blue-primary: #3B778B
$color-blue-dark: #2d7fb8

// Green
$color-green-lighter2: #dff0ea
$color-green-lighter: #ecf2f0
$color-green-light: #19CEB8
$color-green-primary: #12DA0E
$color-green-dark: #0bb208
$color-green-darker: #198754
$color-green-background: #C8E6C9
$color-green-foreground: #256029
$color-green-darkest: #275c6c

// Red
$color-red-light: #FFCDD2
$color-red-primary: #DF320C
$color-red-dark: #B22418
$color-red-text: #C63737

// Purple
$color-purple-lightest: #F6EEF9
$color-purple-light: #C39CF4
$color-purple-medium: #BA99C7
$color-purple-primary: #61246A
$color-purple-dark: #776180
$color-purple-darker: #AD4DD3

// Gray
$color-gray-background2: #EDEEEB
$color-gray-background: #e1e1e1
$color-gray-lightest: #dee2e6
$color-gray-lighter: #F2F2F0
$color-gray-light: #BABABA
$color-gray-medium: #7E7E7E
$color-gray-light-dark: #555555
$color-gray-darker: #333333

// Yellow
$color-yellow-background: #FFD8B2
$color-yellow-foreground: #805B36
$color-yellow-primary: #e8ca37

// Orange
$color-orange-ligh: #fff2e2
$color-orange-primary: #F59E0B
$color-orange-dark: #D97706
$color-orange-darker: #de4b00
