.content-wrapper
    .main-container
        .k-call-bar
            height: $callbar-height
    &.no-sidebar
        .k-call-mngt-container
            left: 0
            width: 100%

.k-call-mngt-container
    padding: 10px 25px
    position: fixed
    left: 0
    bottom: 0
    width: 100%
    z-index: 2500

    &.INACTIVE
        display: none
        padding: 0

    &.status-connect,
    &.status-accept,
    &.direction-OUTGOING
        background-color: $color-green-light

    &.direction-INCOMING
        background-color: #FFE717

        .call-info .title
            color: $color-gray-darker

    &.status-closed
        background-color: #537689

    .top-section
        display: flex
        align-items: center
        justify-content: space-between

        // Call information: call status + duration timer + phone
        .call-info
            display: flex
            align-items: center
            width: 50%
            
            .title
                font-family: 'WorkSans-Black', sans-serif
                font-size: 22px
                color: $color-white

            .duration
                font-family: 'WorkSans-Black', sans-serif
                font-size: 22px
                color: $color-white
                margin-left: 10px

            .phone
                font-family: 'WorkSans-Black', sans-serif
                font-size: 22px
                color: $color-gray-darker
                margin-left: 1rem

            .pt-info
                display: flex

            .brand-logo
                max-width: 100%
                max-height: 25px
                height: auto
                margin-left: 1rem

            .kannact-id
                margin-left: 1rem
                font-size: 1rem

        // Call action (answer, hang up, un/mute, etc.)
        .call-actions
            display: flex
            align-items: center
            justify-content: flex-end

            button
                margin: 0 25px

    // Call engagement (when the call ends)
    .bottom-section
        .call-engagement
            margin-top: 20px
            display: flex

            .k-input-container
                // margin-bottom: 0

                .k-field-container
                    background-color: $color-white
                    border-radius: 4px

            .row
                flex: 1

            .complete-engagement
                margin: 0 20px

@include media-breakpoint-down(xl)
    .k-call-mngt-container .top-section .call-info
        width: 100%
        flex: 1

    .k-call-mngt-container .top-section .call-info .pt-info
        display: flex
        align-items: center
        justify-content: flex-start 

    .k-call-mngt-container.direction-INCOMING .top-section .call-actions button
        margin: 0
        &:not(:last-child)
            margin: 0 .5rem 0 0

@include media-breakpoint-down(lg)
    .content-wrapper .main-container .k-call-bar
        height: auto

    .k-call-mngt-container .top-section .call-info
        display: block
        width: 100%

    .k-call-mngt-container .top-section .call-info .pt-info
        display: flex
        align-items: center
        justify-content: flex-start 

@include media-breakpoint-down(md)
    .k-call-mngt-container .top-section .call-info .phone
        display: inline-block
        margin-left: 0.5rem

    // .k-call-mngt-container .top-section .call-info .pt-info
    //     display: flex
    //     align-items: center
    //     justify-content: flex-start 

    .k-call-mngt-container .top-section .call-actions
        flex-direction: column

    .k-call-mngt-container.direction-INCOMING .top-section .call-actions button
        width: 100%
        margin: 0
        &:not(:last-child)
            margin: 0 0 .5rem 0

@include media-breakpoint-down(sm)
    .content-wrapper .main-container .k-call-bar
        height: auto

    .k-call-mngt-container .top-section
        display: block

    .k-call-mngt-container .top-section .call-info
        display: block
        width: 100%

    .k-call-mngt-container .top-section .call-info .phone
        display: block
        margin-left: 0

    .k-call-mngt-container .top-section .call-info .pt-info
        display: block

    .k-call-mngt-container .top-section .call-info .brand-logo
        max-height: 35px
        margin-left: 0
    
    .k-call-mngt-container .top-section .call-info .kannact-id
        vertical-align: text-top

    .k-call-mngt-container .top-section .call-actions
        margin: .5rem 0
        justify-content: center
        flex-direction: row

    .k-call-mngt-container.direction-INCOMING .top-section .call-actions button
        width: 50%
        margin: 0
        &:not(:last-child)
            margin: 0 .5rem 0 0