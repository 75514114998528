.p-autocomplete
	width: 100%

	// Hover
	&:hover
		.p-autocomplete-input
			border-width: 2px
		.p-autocomplete-dropdown
			border-width: 2px

	// Focus
	&.p-inputwrapper-focus
		.p-autocomplete-dropdown,
		.p-autocomplete-dropdown:hover
			border-width: 2px
			border-color: $color-purple-primary

	// Invalid
	&.p-invalid
		.p-autocomplete-dropdown
			border-color: $color-red-primary

		&:hover
			.p-autocomplete-dropdown
				border-color: $color-red-primary

	// As a dropdown
	&.p-autocomplete-dd .p-autocomplete-input
		border-right: none

	// Dropdown button
	.p-autocomplete-dropdown
		color: $color-gray-medium
		background-color: $color-white
		border-color: $color-gray-medium
		border-left: none

		&:hover
			border-width: 2px
			border-color: $color-gray-medium
			background-color: $color-white
			color: $color-purple-primary

		&.p-disabled
			opacity: 0.65

// Options panel
.p-autocomplete-panel
	.p-autocomplete-items
		.p-autocomplete-item
			font-family: 'Roboto-Regular', sans-serif
			color: $color-gray-darker
			white-space: normal

			&:hover
				background-color: $color-purple-lightest
				color: $color-purple-primary
