.p-dialog
	background-color: $color-white
	
	.p-dialog-header
		border-bottom: none
		padding-bottom: 0
		color: $color-gray-darker
		font-family: 'Roboto-Bold'

	.dialog-custom-header
		display: flex
		align-items: center
		justify-content: flex-start
		
		&__title
			color: $color-gray-darker
			font-family: 'Roboto-Bold'
		&__actions
			margin-left: 1rem


	.modal-header
		.modal-actions
			text-align: end

			button:not(:last-child)
				margin-right: 15px

			.validation-message
				margin-top: 10px
				font-family: 'Roboto-Regular'
				font-weight: 400

	.p-dialog-content
		color: $color-gray-darker
		font-family: 'Roboto-Regular'

		.p-confirm-dialog-message
			margin-left: 0

	.p-dialog-footer
		border-top: none
		
		button
			min-width: 5rem

		@media (max-width: 576px)
			text-align: unset
			display: flex

			button
				flex: 1
