.k-searchbar
	display: flex
	align-items: center
	width: 100%

	.p-inputgroup
		margin-bottom: 0

		input
			width: 100%
			height: 36px
			border-radius: 2rem
			border-top-right-radius: 0
			border-bottom-right-radius: 0

		button:last-child
			border-top-right-radius: 2rem
			border-bottom-right-radius: 2rem
