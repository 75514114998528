.p-overlaypanel
	border-radius: 8px
	border-color: $color-gray-light

// Form message
.form-message
	margin-bottom: 15px

// Bottom actions
.action-buttons
	margin-top: 15px
	text-align: right

	button:not(:last-child)
		margin-right: 15px

	@media (max-width: 576px)
		text-align: unset
		display: flex

		button
			flex: 1


.secondary-title
	color: $color-black
	font-family: "Roboto-Bold"
	font-size: 18px

.title-section
	display: flex
	justify-content: flex-start
	align-items: center
	margin-bottom: 1rem

	.secondary-title
		margin-right: 1rem

// Loading spinner & message
.message-container
	font-family: 'Roboto-Regular'
	font-size: 1rem
	color: $color-gray-darker

	&.loading,
	.message-icon
		color: $color-gray-light-dark

	&.has-error
		color: $color-red-primary

	.message-icon
		margin-right: 10px

.form-container
	padding: 1rem
	background-color: $color-green-lighter

.form-section
	&__header
		padding-bottom: 1rem

	&__content
		padding: 1rem
		background-color: $color-green-lighter
		border-radius: 8px
