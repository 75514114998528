.k-field-message-container
    display: flex
    align-items: center
    justify-content: flex-start
    word-break: break-word

    &.INFO
        color: $color-blue-primary
        .k-field-icon
            color: $color-blue-primary
    &.SUCCESS
        color: $color-green-darker
        .k-field-icon
            color: $color-green-darker
    &.WARNING
        // color: $color-red-light
        .k-field-icon
            // color: $color-red-light
    &.DANGER
        color: $color-red-primary
        .k-field-icon
            color: $color-red-primary
    &.LOADING
        color: $color-gray-light-dark
        .k-field-icon
            color: $color-gray-light-dark

    .k-field-message
        font-size: 14px
        margin-left: 10px
