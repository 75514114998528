.survey-results
	list-style: none
	margin: 0
	padding: 0

	.result
		display: flex
		align-items: center
		padding: .5rem
		border-bottom: 1px solid $color-gray-lightest

		&:hover
			background-color: #EDEEEB

	.p-panel-content
		padding: 0
		border: none

	.result-expanded
		padding: 1rem
		margin: 0
		background-color: #EDEEEB

		.result-name
			color: $color-gray-light-dark
			font-family: 'Roboto-Bold', sans-serif

		.result-value
			margin-bottom: .5rem

	.result-name
		flex: .3
		font-family: 'Roboto-Bold', sans-serif

	.result-value
		flex: 1
		color: $color-gray-darker
