.k-extensionpad
    max-width: 200px

    .input-container
        display: flex
        justify-content: flex-start

        .k-input-container
            flex: 1

        button
            margin-top: 5px
            margin-left: 15px
            margin-bottom: 25px // To match the input-text margin bottom, so they can align vertically