.k-page-title
	position: relative
	font-weight: bold
	font-size: 32px
	padding: 15px
	color: $color-blue-dark
	margin-bottom: 20px

	&::before
		position: absolute
		left: 0
		top: 25%
		content: ""
		width: 5px
		height: 50%
		background-color: $color-blue-dark