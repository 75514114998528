.k-link
    display: inline-flex
    align-items: center
    // color: $color-blue-dark
    color: $color-gray-light-dark
    // text-decoration: none
    // border-bottom: 1px dotted $color-blue-dark
    font-family: 'Roboto-Regular', sans-serif

    &:hover
        // color: $color-blue-medium
        color: $color-gray-medium
        // text-decoration: none

    &.has-icon.has-label
        .k-link-icon
            margin-right: 10px
