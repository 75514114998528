.p-password
	width: 100%

	// Toggle mask icon
	i
		color: $color-gray-medium
		&:hover
			cursor: pointer
			color: $color-purple-primary

	// When the input is disabled, make the toggleMask icon not clickable
	.p-password-input.p-disabled + i
		cursor: default !important
		pointer-events: none
		-webkit-user-select: none
		user-select: none
		opacity: 0.65

.k-inputpassword-container
	.input-icon
		z-index: 50

	.input-icon + .p-password .p-password-input
		padding-left: 2.5rem
