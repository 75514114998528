.k-panel-list
	margin-bottom: 15px
	padding: 5px 10px
	border-radius: 4px
	background-color: $color-white
	box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px

	.panel-list-header
		display: flex
		align-items: center
		padding: .5rem 0
		// border-bottom: 1px solid $color-gray-medium

		.header-title
			font-family: 'Roboto-Black', sans-serif
			font-size: 1.125rem
			color: $color-gray-darker
			margin-right: 10px

		.p-panel-header-icon.p-panel-toggler
			color: $color-gray-darker
			&:focus
				outline: none
				box-shadow: none

	.p-panel-content
		padding: 10px 0
		border: none

	.panel-no-data
		font-family: 'Roboto-Regular', sans-serif
		font-size: 1rem
		color: $color-gray-darker
		margin-bottom: 0

		&.loading,
		.message-icon
			color: $color-gray-light-dark

		&.has-error
			color: $color-red-primary

		.message-icon
			margin-right: 10px

	.panel-list
		list-style: none
		margin: 0
		padding: 0

		.panel-list-item
			.item-label
				margin-right: 10px
				font-family: 'Roboto-Bold', sans-serif
				font-size: 1rem
				color: $color-gray-darker

			.item-value
				font-family: 'Roboto-Regular', sans-serif
				font-size: 1rem
				color: $color-gray-darker
