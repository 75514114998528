.login__page-layout
    display: flex
    align-items: center
    justify-content: center
    height: 100vh
    background-color: #eff3f8


.login__form-layout
    display: flex
    flex-direction: column
    padding-top: 100px
    padding-bottom: 40px
    padding-left: 40px
    padding-right: 40px
    border-radius: 4px
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px
    background-color: $color-white
    width: 100%
    max-width: 500px

.login__welcome
    &-container
        margin-bottom: 60px
        text-align: center
    &-image
        max-width: 250px
        margin-bottom: 30px
    &-text
        font-family: 'Roboto-Bold', sans-serif


.login__error
    margin-bottom: 10px
    
    .k-field-message
        font-size: 16px