.history-collapsible-container
	ul
		padding: 0
		margin: 0
		list-style: none

		li
			&:not(:last-child)
				margin-bottom: .5rem

			.description
				font-family: 'Roboto-Bold', sans-serif
				margin-right: .5rem

.smart-goals-container

	.section-header
		margin-bottom: .5rem

	.section-title
		font-family: 'Roboto-Bold', sans-serif
		font-size: 1.15rem
		color: $color-gray-darker

	.no-smart-goals-container
		display: flex
		align-items: center
		justify-content: center
		border: 1px solid $color-gray-lightest
		border-radius: 15px
		padding: 2rem
		background-color: $color-white
		font-family: 'Roboto-Regular', sans-serif
		font-size: 1rem

	.active-smart-goals-container
		margin-bottom: 2rem

		.section-header
			display: flex
			align-items: center

			.section-title
				margin-right: .5rem

			.action-right
				flex: 1
				text-align: right

	.healthy-habit-container
		border: 1px solid $color-gray-lightest
		border-radius: 15px

		&:not(:last-child)
			margin-bottom: 1rem

		&.IN_PROGRESS, &.EXPIRED
			.header
				background-color: $color-green-lighter

				.form-field-container
					.form-field .field-bold
						color: $color-green-darkest

				.description
					color: $color-green-darkest
				.value
					color: $color-green-darkest

		.header
			padding: 1rem
			border-top-left-radius: 15px
			border-top-right-radius: 15px

			.form-field-container
				display: flex
				align-items: baseline

				.k-input-container
					flex: 1

				.form-field
					margin-right: .5rem
					.field-bold
						font-family: 'Roboto-Bold', sans-serif
					.field-required
						color: $color-red-primary

			&.read-mode
				display: flex
				.info
					flex: 1

				.description
					font-family: 'Roboto-Bold', sans-serif
					font-size: 1rem
					margin-right: .5rem

				.value
					font-family: 'Roboto-Regular', sans-serif

		.content
			padding: 1rem

			.content-field
				margin-bottom: .5rem

				.field-bold
					font-family: 'Roboto-Bold', sans-serif
					font-size: 1rem
					margin-bottom: 0

				.field-required
					color: $color-red-primary
					margin-right: .5rem

				.field-description
					font-family: 'Roboto-Regular', sans-serif
