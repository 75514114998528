.appointments-summary
	list-style: none
	padding: 0
	margin: 0
	// background-color: $color-blue-lightest
	// border-radius: 8px

	.summary-item
		display: flex
		justify-content: space-between
		padding: .5rem 1.5rem
		border-bottom: 1px solid $color-gray-lighter

		&:first-child
			border-top: 1px solid $color-gray-lighter

		.item-desc
			// color: $color-gray-light-dark

		.item-value
			color: $color-gray-darker

.time-slots-container
	max-height: 280px
	overflow-y: auto

.k-table.p-datatable.p-datatable-hoverable-rows
	.p-datatable-tbody
		.appointment-block-row + .p-datatable-row-expansion:not(.p-highlight):not(.p-datatable-emptymessage):hover
			background: $color-white

.appointments-block-info

	.block-info-list
		margin: 0
		padding: 0
		list-style: none

		.block-info
			width: 70%
			margin-left: auto
			margin-right: auto
			display: grid
			grid-template-columns: 150px auto 160px 200px
			grid-template-rows: auto
			padding: .5rem 1.5rem

			&:hover
				background-color: $table-row-hover-bg

			&:not(:last-child)
				border-bottom: 1px solid $color-gray-lighter

			.full-name
				align-self: center

			.p-tag
				padding: 0.25em 0.5rem
				width: auto
