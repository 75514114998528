// Input: icon inside
.p-inputgroup
    margin-bottom: 5px

    .p-inputtext
        width: 100%

    .p-inputgroup-addon + .k-field-container .p-inputtext
        border-left: none

.p-inputgroup-addon
    background: $color-white
    color: $color-gray-medium
    border-color: $color-gray-medium

.p-inputgroup-addon:last-child
    border-left: none
    border-right-color: $color-gray-medium

// Input: label
.k-field-container
    width: 100%

    // Required field -> add *
    &.is-required .k-field-label::after
        content: ' *'
        color: $color-red-primary

.k-field-label
    font-family: 'Roboto-Regular', sans-serif

.p-float-label > label
    color: $color-gray-medium
    font-size: 16px

.p-float-label.p-invalid label
    color: $color-red-primary

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label
    font-size: 14px
    top: 0.1rem
    left: 0.65rem
    padding: 0 4px
    background-color: $color-white
    border-radius: 2px
    color: $color-gray-darker

// Input: container
.k-input-container
    margin-bottom: 20px

    .k-input-wrapper
        width: 100%

    // Icons
    .p-input-icon-left > i:first-of-type,
    .input-icon,
    .p-input-icon-right > i:last-of-type,
    .p-input-icon-right > svg:last-of-type,
    .p-input-icon-right > .p-input-suffix
        color: $color-gray-medium

        &.is-clickable:hover
            cursor: pointer
            color: $color-purple-primary

    .input-prefix
        top: 1.3rem
