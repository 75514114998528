.participant-container
  height: 100%
  display: flex
  flex-direction: column

  .participant-profile-container
    padding: .5rem 1rem
    margin-bottom: 15px
    border-radius: 4px
    border-left: 15px solid transparent
    background-color: $color-white
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px

    &.ACTIVE
      border-left-color: $color-green-background
    &.PROSPECTIVE
      border-left-color: $color-blue-background
    &.DNC
      border-left-color: $color-red-light
    &.INACTIVE,
    &.NOT_COMPUTED
      border-left-color: $color-yellow-background

    .participant-profile-message
      padding-bottom: 15px
      .k-field-message-container .k-field-icon,
      .k-field-message-container .k-field-message
        font-size: 1.5rem

  .participant-content
    flex: 1
    overflow: auto
    padding: 1px

@include media-breakpoint-down(lg)
  .participant-container
    overflow-y: scroll
    padding-right: 8px // Vertical scroll padding (to avoid horizontal scrollbar)

    .participant-content
      overflow: visible