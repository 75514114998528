.p-inputnumber
	width: 100%

.k-inputnumber-container
	.input-icon
		z-index: 50

	.p-input-icon-left .p-inputnumber-input
		padding-left: 2.5rem

	.p-input-icon-right .p-inputnumber-input
		padding-right: 2.5rem
