.conditions-modal

	.condition-create-form
		display: flex
		align-items: center

		.message-content
			padding: 1rem 1rem 2rem 1rem
	
		&__remove
			justify-self: flex-end
			min-width: 42px

			button
				margin-top: 1rem

		.form-section
			margin-top: 1rem
			flex: 1

		.k-input-container
			margin-bottom: 0

	@media (max-width: 768px)
	.condition-create-form
		.k-input-container
			margin-bottom: .5rem