.k-table.p-datatable.GREEN

	// Nested table
	&.nested-table .p-datatable-tbody tr.p-datatable-row-expansion > td
		padding: 0

	/* HEADER */

	.table-header
		padding: 0

	.table-title
		color: $color-green-darkest

	.p-datatable-table
		border-spacing: 0px 10px
		border-collapse: separate

	/* HEAD */

	// Head: Columns
	.p-datatable-thead tr > th
		color:$color-gray-light-dark
		background-color: $color-white
		border: none
		padding: 0px 8px
		text-transform: uppercase
		font-size: 14px

	// Head: Columns: sortable
	.p-sortable-column
		// hover, Focus, Active, Active+Hover
		&.p-highlight,
		&.p-highlight:not(.p-sortable-disabled):hover,
		&:not(.p-sortable-disabled):hover,
		&:not(.p-highlight):not(.p-sortable-disabled):hover,
		&:focus
			color: $color-green-darkest
			background-color: $color-white

			.p-sortable-column-icon
				color: $color-green-darkest

		.p-sortable-column-icon
			color: $color-gray-light-dark


	/* BODY */

	// Body: Rows: hover
	&.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover
		background-color: $color-green-lighter2
		color: $color-gray-darker

	// Body: Message
	.p-datatable-tbody > tr.p-datatable-emptymessage 
		.k-empty-message
			.k-field-message,
			.k-field-icon
				color: $color-green-darkest

			&.loading-message
				background-color: $color-green-lighter
				
				.k-field-message,
				.k-field-icon
					color: $color-green-darkest
	
	// Body: Rows: inactive
	.p-datatable-tbody > tr.row-inactive
		background-color: $color-gray-lighter !important

	// Body: Rows
	.p-datatable-tbody > tr
		color: $color-gray-darker
		background-color: $color-green-lighter
	
	// Body: Rows: Cells
	.p-datatable-tbody > tr > td
		border: none

	// Expandable rows
	.p-datatable-tbody > tr.p-datatable-row-expansion
		border-spacing: 0 0
		background-color: $color-white !important

		> td:not(.row-cell)
			padding: 0
			




	/* PAGINATOR */
		
	.p-paginator.p-paginator-bottom
		border: none
		padding-top: 0

	.footer-current-page
		color: $color-gray-darker

	.footer-rpp-text
		color: $color-gray-darker

	.p-paginator
		.p-paginator-first,
		.p-paginator-prev,
		.p-paginator-next,
		.p-paginator-last
			background-color: transparent
			border-color: transparent
			color: $color-gray-light-dark

			&:hover,
			&:not(.p-disabled):not(.p-highlight):hover
				background-color: $color-green-lighter
				border-color: $color-green-lighter
				color: $color-green-darker

			&.p-disabled
				background-color: transparent
				border-color: transparent
				color: $color-gray-light

	.p-paginator .p-paginator-pages	.p-paginator-page
		background-color: transparent
		border-color: transparent
		color: $color-gray-light-dark

		&:not(.p-highlight):hover
			background-color: $color-green-lighter
			border-color: $color-green-lighter
			color: $color-green-darker

		&.p-highlight
			background-color: $color-green-lighter
			border-color: $color-green-lighter
			color: $color-green-darker