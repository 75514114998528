.p-tag
    padding: 5px 15px
    font-family: 'Roboto-Bold', sans-serif
    text-transform: capitalize
    width: 130px
    &.p-tag-rounded
        border-radius: 5px
    &.p-tag-danger
        background-color: $color-red-light
        color: $color-red-text
    &.p-tag-success
        background-color: $color-green-background
        color: $color-green-foreground
    &.p-tag-info
        background-color: $color-blue-background
        color: $color-blue-foreground
    &.p-tag-warning
        background-color: $color-yellow-background
        color: $color-yellow-foreground
    &.p-tag-default
        background-color: $color-gray-background
        color: $color-gray-light-dark
