.biometrics-form
	.key
		font-family: 'Roboto-Regular', sans-serif
		font-size: 1rem
		color: $color-gray-darker
		text-align: left
		margin-bottom: .25rem

	.input-separator
		margin: 0 .5rem 20px

	.input-unit
		display: flex
		align-items: center

	.input-width
		// padding: 0 10px

		&.field-2
			width: 60px
		&.field-3
			width: 70px
		&.field-4
			width: 80px
		&.field-5
			width: 90px

	.unit
		font-family: 'Roboto-Regular', sans-serif
		font-size: 1rem
		color: $color-gray-darker
		margin-left: .5rem
		margin-bottom: 20px // k-input-container spacing

		&.top
			align-self: flex-start

.biometrics-charts
	.chart-filter-title
		font-family: 'Roboto-Bold', sans-serif
		font-size: 1rem
		margin-right: 1rem

	&__glucose-trend-analysis-link
		overflow-wrap: break-word

	.chart-filters
		margin-bottom: 1rem

		.chart-filter-col
			display: flex
			align-items: center
			flex-wrap: nowrap

		.chart-selector
			.p-button
				color: $color-purple-primary
				border-color: $color-purple-primary
				background-color: $color-white
				border-radius: 2rem
				margin: 0 .5rem .5rem 0
				border-right: 1px solid $color-purple-primary
				padding: 0.35rem 0.5rem
				font-size: 1rem

				&:hover:not(.p-highlight)
					color: $color-purple-primary
					background-color: $color-purple-lightest
					border-color: $color-purple-primary
					border-right: 1px solid $color-purple-primary

				&.p-highlight
					background-color: $color-purple-primary
					border-color: $color-purple-primary
					color: $color-white
					border-right: 1px solid $color-purple-primary

	.chart-info-container
		display: flex
		align-items: center
		justify-content: center

		.chart-info-container
			&:not(:last-child)
				margin-right: 1.5rem

	.k-empty-message
		display: flex
		justify-content: flex-start
		// padding: 1rem 0

		.k-field-message-container .k-field-message
			font-size: 1rem

@media (max-width: 1450px)
	.biometrics-device-summary .k-inputradio-container .k-field-container
		padding-top: 1rem

@media (max-width: 820px)
	.biometrics-device-summary .k-inputradio-container .k-field-container
		padding-top: 2rem