.k-table.p-datatable
	width: 100%

	/* TABLE (GENERAL) */

	// When used as a card
	&.as-card
		padding-top: 10px
		border-radius: 4px
		background-color: $color-white
		box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px

		.table-header
			padding: 0 20px .75rem 20px

		.table-title
			font-size: 1.5rem


	/* HEADER */

	.p-datatable-header
		background-color: $color-white
		border: none
		padding: 0

	.table-header
		display: flex
		align-items: center
		padding: 0 $table-cell-padding .75rem $table-cell-padding

	.table-header-title
		flex: 1

	.table-title
		margin-bottom: 0
		font-family: 'Roboto-Black', sans-serif
		font-size: 1.15rem
		position: relative
		display: inline-block

		&.has-custom-header
			margin-right: 2rem

	// Header actions: search, refresh
	.table-actions
		flex: 1
		text-align: end
		display: flex
		justify-content: flex-end
		align-items: center

		.action-spacer
			margin: 0 $table-cell-padding

		.filters-icon
			font-size: 1.25rem
			fill: $color-purple-primary
			cursor: pointer

		.crud
			margin: 0 2px

		button
			font-size: 14px
			padding: 0.4rem 0.75rem

	
	/* HEAD */

	// Head: Columns
	.p-datatable-thead > tr > th
		font-family: 'Roboto-Bold', sans-serif
		padding: $table-cell-padding
		font-size: $table-header-fs
		text-transform: uppercase

	// Head: Columns: sortable
	.p-sortable-column
		// Focus, Active, Active+Hover
		&.p-highlight,
		&.p-highlight:not(.p-sortable-disabled):hover,
		&:not(.p-sortable-disabled):hover,
		&:focus
			box-shadow: none

	/* BODY */

	// Body: Message
	.p-datatable-tbody > tr.p-datatable-emptymessage
		td
			padding: 0
			margin: 0

		.k-empty-message
			text-align: center
			padding: 2rem 1rem

			.k-field-message-container
				justify-content: center

				.k-field-icon
					font-size: 1.5rem

				.k-field-message
					font-size: 1.125rem

	// Body: Rows
	.p-datatable-tbody > tr
		font-family: 'Roboto-Regular', sans-serif
		font-size: $table-cell-fs
		height: 50px

	// Body: Rows: inline form
	.p-datatable-tbody > tr.inline-form
		.p-button:not(:last-child)
			margin-right: 10px

		.k-input-container
			margin-bottom: 0

			.p-inputtext
				height: 40px

		.p-inputtextarea
			height: 40px

		.k-autocomplete-container .p-autocomplete-dropdown
			height: 40px

		.k-inputdate-container .p-calendar .p-datepicker-trigger
			padding: 5px 0
		
		.k-select-container 
			.p-dropdown
				height: 40px
				.p-dropdown-label
					height: 38px
					padding-top: 7px

	// Body: Rows: Cells
	.p-datatable-tbody > tr > td.row-cell
		padding: $table-cell-padding

		/* Templates styling */
		audio
			width: 100%
			vertical-align: middle
			height: 30px

		// Template: ACTIONS
		&.actions-template
			.actions-template-button
				.p-splitbutton-defaultbutton
					display: none
				.p-splitbutton-menubutton
					color: $color-purple-primary
					border: 1px solid $color-purple-medium
					background-color: transparent
					padding: 8px
					height: 35px
					width: 35px
					border-radius: 50%
					transition: background-color .3s, color .3s

					&:hover
						color: $color-white
						background-color: $color-purple-primary
						border-color: $color-purple-primary

		// Template: BUTTONS
		&.buttons-template button:not(:last-of-type)
			margin-right: 10px

		// Template: TAG
		&.tag-template
			.p-tag
				padding: 0.25em 0.5rem
				width: auto

		// Clickable icons
		.action-icon
			cursor: pointer
			color: $color-purple-primary
			border: 1px solid $color-purple-medium
			background-color: transparent
			padding: 8px
			height: 17px
			width: 17px
			border-radius: 50%
			transition: background-color .3s, color .3s

			&:hover
				color: $color-white
				background-color: $color-purple-primary
				border-color: $color-purple-primary

		// Template: TWILIO_AUDIO
		.twilio-audio-template
			.audio-placeholder
				cursor: pointer
				color: $color-purple-primary
				fill: $color-purple-primary
				font-size: 2rem

				&:hover
					fill: $color-purple-darker
					color: $color-purple-darker


	/* PAGINATOR */

	// Paginator: current page (showing items X - Y of Z total)
	.footer-current-page
		font-family: 'Roboto-Regular', sans-serif
		font-size: 1rem
		flex: 1

	// Paginator: rows per page (rpp)
	.footer-rpp
		display: flex
		justify-content: flex-end
		align-items: center
		flex: 1

		// Paginator: rows per page (rpp): select
		.p-dropdown
			height: 38px
			display: flex
			align-items: center

			.p-dropdown-label
				height: auto

	// Paginator: rows per page (rpp): text
	.footer-rpp-text
		font-family: 'Roboto-Regular', sans-serif
		font-size: 1rem
	
	.p-paginator
		.p-paginator-first,
		.p-paginator-prev,
		.p-paginator-next,
		.p-paginator-last
			border-radius: 50%
			margin: 0 2px

			&:focus:not(:focus-visible)
				box-shadow: none

	.p-paginator-pages
		margin: 0 20px

	.p-paginator .p-paginator-pages .p-paginator-page
		border-radius: 50%
		margin: 0 2px
