.p-button
    background-color: $color-purple-primary
    border: 1px solid $color-purple-primary
    color: $color-white
    font-family: 'Roboto-Regular', sans-serif
    font-size: 16px

    &.inline-form
        padding: 0.7rem 0.75rem

    &.p-button-xs
        font-size: 0.875rem
        padding: 0.2rem 0.5rem

        &.p-button-icon-only.p-button-rounded
            height: 1.85rem
            width: 1.85rem
    
    &.p-button-sm
        &.p-button-icon-only.p-button-rounded
            height: 37px
            width: 37px

    &:enabled:hover
        background-color: $color-purple-darker
        border-color: $color-purple-darker
        box-shadow: none

    &:focus
        box-shadow: none

    &.p-disabled
        color: $color-gray-medium
        background-color: $color-gray-lighter
        border-color: $color-gray-lighter
        opacity: 1

    // Outlined
    &.p-button-outlined
        color: $color-purple-primary
        border-color: $color-purple-primary

        &:enabled:hover
            color: $color-purple-darker
            background-color: transparent
            border-color: $color-purple-darker

        &.p-disabled
            opacity: 1
            color: $color-gray-medium
            background-color: transparent
            border-color: $color-gray-medium

    // Text
    &.p-button-text
        color: $color-purple-primary
        background-color: transparent
        border-color: transparent

        &:enabled:hover
            color: $color-purple-primary
            background-color: $color-purple-lightest
            border-color: $color-purple-lightest

        &.p-disabled
            opacity: 1
            color: $color-gray-medium
            background-color: transparent
            border-color: transparent

        // Text + Success
        &.p-button-success
            color: $color-green-darkest
            background-color: transparent
            border-color: transparent

            &:enabled:hover
                color: $color-green-foreground
                background-color: $color-green-background
                border-color: $color-green-background

            &.p-disabled
                opacity: 1
                color: $color-gray-medium
                background-color: transparent
                border-color: transparent

        // Text + Danger
        &.danger
            color: $color-red-primary
            background-color: transparent
            border-color: transparent

            &:enabled:hover
                color: $color-red-dark
                background-color: transparent
                border-color: transparent

            &.p-disabled
                opacity: 1
                color: $color-gray-medium
                background-color: transparent
                border-color: transparent

        // Text + Neutral
        &.neutral
            color: $color-gray-light-dark
            background-color: transparent
            border-color: transparent

            &:enabled:hover
                color: $color-gray-darker
                background-color: transparent
                border-color: transparent

            &.p-disabled
                opacity: 1
                color: $color-gray-medium
                background-color: transparent
                border-color: transparent

        // Text + Link
        &.link
            font-family: 'Roboto-Bold', sans-serif
            color: $color-blue-primary
            background-color: transparent
            border-color: transparent

            &:enabled:hover
                color: $color-blue-dark
                background-color: transparent
                border-color: transparent

            &.p-disabled
                opacity: 1
                color: $color-gray-medium
                background-color: transparent
                border-color: transparent

    // Success
    &.p-button-success
        color: $color-white
        background-color: $color-green-darker
        border-color: $color-green-darker

        &:enabled:hover
            color: $color-white
            background-color: $color-green-dark
            border-color: $color-green-dark

        &.p-disabled
            opacity: 1
            color: $color-gray-medium
            background-color: $color-gray-lighter
            border-color: $color-gray-lighter

    // Success
    &.p-button-success-darker
        color: $color-white
        background-color: $color-green-darkest
        border-color: $color-green-darkest

        &:enabled:hover
            color: $color-white
            background-color: #32768a
            border-color: #32768a

        &.p-disabled
            opacity: 1
            color: $color-gray-medium
            background-color: $color-gray-lighter
            border-color: $color-gray-lighter

    // Information
    &.p-button-info
        color: $color-white
        background-color: $color-blue-medium
        border-color: $color-blue-medium

        &:enabled:hover
            color: $color-white
            background-color: $color-blue-dark
            border-color: $color-blue-dark

        &.p-disabled
            opacity: 1
            color: $color-gray-medium
            background-color: $color-gray-lighter
            border-color: $color-gray-lighter

    // Danger
    &.p-button-danger
        color: $color-white
        background-color: $color-red-primary
        border-color: $color-red-primary

        &:enabled:hover
            color: $color-white
            background-color: $color-red-dark
            border-color: $color-red-dark

        &.p-disabled
            opacity: 1
            color: $color-gray-medium
            background-color: $color-gray-lighter
            border-color: $color-gray-lighter

    // White
    &.p-button-white
        color: $color-gray-light-dark
        background-color: $color-white
        border-color: $color-white

        &:enabled:hover
            color: $color-white
            background-color: $color-purple-primary
            border-color: $color-purple-primary

        &.p-disabled
            opacity: 1
            color: $color-gray-medium
            background-color: $color-gray-lighter
            border-color: $color-gray-lighter

    // Table button
    &.p-button-table
        color: $color-purple-primary
        border-color: $color-purple-medium
        background-color: $color-white
        border-radius: 50%
        height: 2.357rem
        transition: background-color .3s, color .3s

        &:hover
            color: $color-white
            background-color: $color-purple-primary
            border-color: $color-purple-primary
