.institution-details_overlaypanel
  min-width: 300px
  max-height: 800px
  font-size: 1rem
  overflow-y: auto

  .institution-details_overlaypanel__markdown
    max-width: 650px

  .p-overlaypanel-content
    padding: 0.5rem

  .institution-details__container
    .institution-details__title
      font-weight: 600

    .institution-details__list
      padding-left: 1rem
      margin: 0

.institution-details_button-no-focus
  margin-left: 5px
  &:focus
    outline: 0
    box-shadow: none
