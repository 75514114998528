.k-header-container
    height: $header-height
    background-color: $header-bg-color
    box-shadow: $header-box-shadow
    display: flex
    align-items: center
    position: fixed
    top: 0
    left: 0
    width: 100%
    z-index: 999

    .header-logo
        width: auto
        padding: 0 22px
        height: 100%
        max-height: $header-height

        img
            height: 100%
            max-width: 100%
            color: $color-purple-primary

    .header-menu
        margin: 0 auto 0 0
        padding: 0
        list-style: none
        display: flex

        li
            margin: 0 10px

        .menu-item
            display: inline-flex
            justify-content: center
            align-items: center
            position: relative
            color: $header-item-color
            font-family: 'Roboto-Bold', sans-serif
            font-size: 16px
            border-bottom: 3px solid transparent
            padding: $header-item-padding
            cursor: pointer
            transition: border-color .2s, color .2s
            text-decoration: none

            &:hover, &:focus
                color: $header-item-color-active
                background-color: transparent
                border-color: transparent

            &.active
                color: $header-item-color-active
                background-color: transparent
                border-color: $header-item-color-active

    // Switch: admin/coach view
    .switch-view
        display: flex
        align-items: center
        margin: 0 $header-spacer

        .view-mode
            margin: 0 10px
            color: $header-item-color
            font-family: 'Roboto-Bold', sans-serif
            font-size: 16px

            &.current
                color: $header-item-color-active

        .p-inputswitch
            width: 3rem
            height: 1.75rem

            &.p-inputswitch-checked
                .p-inputswitch-slider
                    background-color: $header-item-color-active

            .p-inputswitch-slider
                background-color: $header-item-color-active
                border: .5px solid $header-item-color
                border-radius: 30px

                &:before
                    border-radius: 50%

    // Logout
    .header-logout
        display: inline-flex
        justify-content: center
        align-items: center
        position: relative
        padding: $header-item-padding
        margin: 0 $header-spacer
        color: $header-item-color
        font-family: 'Roboto-Bold', sans-serif
        font-size: 16px
        cursor: pointer
        transition: border-color .2s, color .2s
        text-decoration: none

        &:hover, &:focus
            color: $header-item-color-active
            background-color: transparent

        &.active
            color: $header-item-color-active
            background-color: transparent

        .logout-icon
            font-size: 1.25rem
            margin-right: 15px
