.k-select-button-container

	/* Label */
	&.has-label
		padding-top: 30px

	.p-float-label label
		top: 0
		left: 0
		margin-top: -1.75rem
		color: $color-gray-darker

	.p-selectbutton.p-buttonset

		/* Alignment: horizontal/vertical */
		&.horizontal .p-button
			margin: 0 .5rem .5rem 0

		&.vertical .p-button
			display: block
			margin: 0
			&:not(:last-child)
				margin-bottom: .5rem

		/* State: invalid */
		&.p-invalid
			.p-button
				border-color: $color-red-primary
				&:not(:last-child)
					border-color: $color-red-primary

		/* Buttons */
		.p-button
			color: $color-purple-primary
			border-color: $color-purple-primary
			background-color: $color-white
			border-top-left-radius: 2rem
			border-top-right-radius: 2rem
			border-bottom-left-radius: 2rem
			border-bottom-right-radius: 2rem
			padding: 0.35rem 0.5rem
			// font-size: 1rem

			&:hover:not(.p-highlight)
				color: $color-purple-primary
				background-color: $color-purple-lightest
				border-color: $color-purple-primary
				border-right: 1px solid $color-purple-primary

			&.p-highlight
				background-color: $color-purple-primary
				border-color: $color-purple-primary
				color: $color-white
				border-right: 1px solid $color-purple-primary

			&:not(:last-child)
				border-right: 1px solid $color-purple-primary
