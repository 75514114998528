$pt-dashboard-sidebar-width: 380px

.pt-dashboard
	display: flex
	height: 100%

	&__main
		padding: 0 0px
		flex-grow: 1

	&__sidebar
		height: 100%
		width: $pt-dashboard-sidebar-width
		display: flex
		flex-direction: column
		flex-shrink: 0
		padding: 0 0 0 15px

		ul
			list-style: none
			margin: 0
			padding: 0

@include media-breakpoint-down(xl)
	.pt-dashboard__main .participant-notes-history
		height: unset
		margin-top: 1rem

@include media-breakpoint-down(lg)
	.pt-dashboard
		flex-direction: column
		height: unset

	.pt-dashboard__main
		order: 2
		display: block

	.pt-dashboard__sidebar
		order: 1
		width: 100%
		height: auto
		padding: 0
		overflow-y: visible
		margin-bottom: 1rem

	.pt-dashboard__sidebar > ul
		display: flex
		flex-direction: row
		// margin: -0.5rem 0 -0.5rem 0
		margin: -0.5rem

	.pt-dashboard__sidebar > ul > li
		flex: 0 0 auto
		width: 33.33333333%
		padding: .5rem

		&:first-child
			margin-left: 0
		&:last-child
			margin-right: 0

	.pt-dashboard__sidebar .k-panel-list
		margin-bottom: 0
		height: 100%

@include media-breakpoint-down(md)
	.pt-dashboard__sidebar > ul
		margin: 0
		flex-direction: column
		margin-bottom: .5rem

	.pt-dashboard__sidebar > ul > li
		width: 100%
		padding: 0
		margin-bottom: .5rem
