#root
	min-height: 100vh
	height: 100%

.content-wrapper
	height: 100%

	&.no-sidebar
		.main-container
			margin-left: 0px

.main-container
	margin-left: $sidebar-width
	padding-top: $header-height
	min-height: 100vh
	height: 100%
	transition: margin-left .2s
	position: relative

	&.active-call
		padding-bottom: $callbar-height

	&.responsive
		margin-left: 0

	.main-content
		padding: 15px
		height: 100%
		position: relative
