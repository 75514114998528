$body-font-size: 14px
$body-bg: #eff3f8 // Light blue
// $body-bg: #fefbff // Light pink
// $body-bg: #F6EEF9 // Light pink
// $body-bg: #f8eff5 // Medium pink
// $body-bg: #f7f7f7 // Light gray

/************************/
/******  HEADER  ********/
/************************/

$header-height: 63px
$header-bg-color: $color-white
$header-box-shadow: 0 2px 5px 0 #61246a2b

$header-spacer: 10px

$header-item-color: $color-gray-light-dark
$header-item-color-active: $color-purple-primary
$header-item-padding: 18px 15px

$header-switch-color: $color-purple-medium
$header-switch-color-active: $color-purple-primary

/************************/
/******  SIDEBAR  *******/
/************************/

$sidebar-width: 60px
$sidebar-width-expanded: 250px
$sidebar-bg-color: $color-white
$sidebar-box-shadow: 0px 4px 4px #d9ddfc

$sidebar-item-height: 56px
$sidebar-item-color: $color-gray-light-dark
$sidebar-item-bg: $color-white
$sidebar-item-color-active: $color-purple-primary

/************************/
/*****  CALL BAR  *******/
/************************/

$callbar-height: 57px

/************************/
/******   TABLE  ********/
/************************/

$table-cell-padding: .5rem
$table-header-fs: 14px
$table-cell-fs: 1rem
$table-header-color: $color-gray-light-dark
// $table-header-bg: $color-gray-lighter
$table-header-bg: #f8f9fa
$table-header-color-active: $color-purple-primary
$table-row-hover-bg: #EDEEEB
