.participant-details-external-link
	display: flex
	align-items: center

	.k-link
		margin-right: 8px
		margin-bottom: 8px

	.p-button
		margin-bottom: 8px

	.p-button-label
		margin-left: 5px
