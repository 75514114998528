.custom-goals-modal

	&__details
		list-style: none
		margin: 0
		padding: 0

		li
			margin-bottom: .5rem

			.field
				font-family: "Roboto-Bold", sans-serif
				margin-right: 1rem

	&__progress
		border-top: 1px solid #dee2e6

		&--list
			padding: 0
			margin: 0
			list-style: none

			&--date
				font-family: "Roboto-Bold", sans-serif
				margin-right: 2rem

			li
				padding: .5rem 0
				border-bottom: 1px solid $color-gray-lighter
				
				&:last-child
					border-bottom: none
					padding-bottom: 0

	.k-table.p-datatable.GREEN .p-datatable-tbody > tr.p-datatable-row-expansion > td
		border: 1px solid $color-gray-lightest
		border-top: none
		padding: 0 .5rem 1rem
		
.custom-goals-active

	.p-panel
		&:not(:last-child)
			margin-bottom: 1rem
	&_header
		display: flex
		flex-direction: row
		align-items: center

		&_title
			font-family: "Roboto-Black", sans-serif
			font-size: 1.15rem
			color: $color-gray-darker
			margin-right: .5rem

		&_subtitle
			font-family: "Roboto-Regular", sans-serif
			color: $color-gray-darker

		.k-input-container
			margin: 0 1rem 0 0
			.k-inputcheckbox-container.chbx-binary
				padding-top: 0

	.p-panel-content
		.k-input-container
			margin-bottom: 0
