.k-select-container
    .p-dropdown
        width: 100%

    .input-icon
        z-index: 50

    .input-icon + .p-dropdown .p-dropdown-label
        padding-left: 2.5rem

// Select styling (including selects that are inside other components: table pagintor, etc.)
.p-inputgroup > .p-float-label > .p-component
    border-radius: 4px

// Select field
.p-dropdown
    border-radius: 4px
    border-color: $color-gray-medium
    color: $color-gray-darker
    height: 48px

    .p-dropdown-label
        color: $color-gray-darker
        height: 46px // 46px + 1px (border-top) + 1px (border-bottom) = 48px (height of all form components)
        padding-top: 10px

    .p-dropdown-trigger
        color: $color-gray-medium

    &:not(.p-disabled):hover
        border-width: 2px
        border-color: $color-gray-medium

        .p-dropdown-clear-icon:hover
            color: $color-purple-primary
        .p-dropdown-trigger:hover
            color: $color-purple-primary

    &:not(.p-disabled).p-focus
        border-width: 2px
        border-color: $color-purple-primary
        box-shadow: none

        .p-dropdown-clear-icon
            color: $color-purple-primary
        .p-dropdown-trigger
            color: $color-purple-primary

// Select options container
.p-dropdown-panel
    border-radius: 4px

    // Filter options
    .p-dropdown-header
        background-color: $color-gray-lighter

        .p-dropdown-filter-container

            // Clearable filter
            &.p-dropdown-clearable-filter
                .p-dropdown-filter
                    padding-right: 2.5rem

            .p-dropdown-filter
                padding-left: 2.5rem

            .p-dropdown-filter-icon
                color: $color-gray-medium
                left: 0.75rem
                width: fit-content

            .p-dropdown-filter-clear-icon
                color: $color-gray-medium
                right: 0.75rem
                width: fit-content
                &:hover
                    color: $color-purple-primary
                    cursor: pointer

    // Options list
    .p-dropdown-items
        padding: 0

        .p-dropdown-item
            color: $color-gray-darker
            background-color: $color-white

            &:first-child
                border-top-left-radius: 4px
                border-top-right-radius: 4px
            &:last-child
                border-bottom-left-radius: 4px
                border-bottom-right-radius: 4px

            &:not(.p-highlight):not(.p-disabled):hover
                background-color: $color-purple-lightest
                color: $color-purple-primary

            &.p-highlight
                background-color: $color-purple-lightest
                color: $color-purple-primary
