.k-table.p-datatable.NESTED
	.p-datatable-thead
		display: none

	.p-datatable-table
		border-spacing: 0px 10px
		border-collapse: collapse

	.p-datatable-tbody tr
		background-color: $color-white

		&.is-inactive
			background-color: #E8E8E8 !important

	.p-datatable-tbody tr td
		padding: 0px .5rem
		border: none

		
