@media (max-width: 768px)
  .k-table.p-datatable

    // Header
    // .p-datatable-header
    .table-header
      flex-direction: column
      align-items: flex-start

      &.has-actions
        .table-title
          margin-bottom: 1rem

    .table-filters-container
      padding: 0 1rem 1rem
      form
        .filter-col,
        .filter-col:not(:first-child)
          margin: 0 0 .5rem 0

        .filters-title
          display: block
          margin-bottom: .5rem

        .filters-form
          display: block

        .filters-buttons
          margin-top: 1rem

    // Footer
    .p-paginator
      justify-content: center

      .footer-current-page
        width: 100%
        flex: none
        text-align: center
        margin-bottom: 10px

      .footer-rpp
        width: 100%
        flex: none
        justify-content: center
        margin-top: 10px

@media (max-width: 992px)
  .k-table.p-datatable

    // Header
    .p-datatable-header
      .table-header
        display: block

        &.has-actions
          .table-title
            margin-bottom: 1rem

        .table-title
          display: block

        .table-actions
          justify-content: flex-end
