.k-dialpad-overlay
    width: 300px
    .p-overlaypanel-content
        padding: 20px
        .dial-from-text
            font-family: 'Roboto-Regular', sans-serif
            font-size: 16px

        .dial-from-number
            font-family: 'Roboto-Bold', sans-serif
            font-size: 16px
        .dial-to-container
            padding: 20px 00px 0px 0px

            .dial-to-input-container
                display: flex
                
                .dialpad-prefix
                    width: 21px
                    height: 14px
                    background-image: url("https://assets.flex.twilio.com/assets/img/flag-spritesheet.png")
                    -webkit-background-size: 21px
                    background-size: 21px
                    background-position-x: 0px
                    background-position-y: -3346px
                    background-repeat: no-repeat

                .k-input-container
                    flex: 1
                    width: calc(#{width} - #{dialpad-icon} - 10px)
                    margin-bottom: 0px

                button
                    margin-top: 5px
                    margin-left: 15px

                .dialpad-icon
                    display: flex
                    align-items: center
                    justify-content: center
                    margin-top: 5px
                    margin-left: 15px
                    height: 38px
                    width: 38px
                    color: $color-white
                    background-color: $color-purple-primary
                    border-radius: 50%

                    &:hover
                        cursor: pointer
                        background-color: $color-purple-darker

                    &.disabled
                        color: $color-gray-medium
                        background-color: $color-gray-lighter
                        border-color: $color-gray-lighter

                        &:hover
                            cursor: default
                            pointer-events: none !important

            .dial-to-keypad-container
                .dial-to-keypad-header
                    display: none
                    // display: flex
                    align-items: center
                    cursor: pointer
                    border: 1px solid $color-gray-medium
                    border-radius: 4px 
                    padding: 12px 16px
                    color: $color-purple-primary
                    font-size: 16px
                    font-family: 'Roboto-Regular', sans-serif
                    width: fit-content

                    button
                        height: 24px
                    button:hover,
                    button:focus
                        background-color: transparent
                        box-shadow: none
                        border-color: transparent
                        color: $color-purple-primary

                .p-panel-content
                    border: none
                    width: 250px
                    padding-bottom: 0px

.dialkey-row
    display: flex
    flex-direction: row
    -webkit-box-flex: 0
    flex-grow: 0
    margin-bottom: 10px

    .key-item
        width: 33.33%
        display: flex
        -webkit-box-flex: 0
        flex-grow: 0
        flex-direction: column
        -webkit-box-align: center
        align-items: center
        -webkit-box-pack: center
        justify-content: center
        cursor: pointer

        .key-value
            color: $color-purple-primary
            font-size: 26px
            font-family: 'WorkSans-Black', sans-serif
            
        .key-subvalue
            color: $color-gray-medium
            font-size: 14px
            font-family: 'Roboto-Regular', sans-serif