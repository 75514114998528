.p-tabmenu
	margin-bottom: 15px

	.p-tabmenu-nav
		border-color: $color-gray-light

		.p-tabmenuitem
			.p-menuitem-link
				border-color: $color-white $color-white $color-gray-light $color-white
				color: $color-gray-darker
				font-family: 'Roboto-Bold', sans-serif

				&:not(.p-disabled):focus
					box-shadow: none

			&:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link
				border-color: $color-white $color-white $color-gray-light $color-white
				color: $color-gray-darker
				background-color: #F8F9FB

			&.p-highlight .p-menuitem-link
				border-color: $color-white $color-white $color-purple-primary $color-white
				border-width: 2px
				color: $color-purple-primary

			&.p-disabled .p-menuitem-link
				color: $color-gray-light-dark

.p-tabview
	.p-tabview-panels
		color: $color-gray-darker

	.p-tabview-nav
		border-color: $color-gray-light

		li.p-highlight .p-tabview-nav-link
			border: solid $color-purple-primary
			border-width: 0 0 2px 0
			color: $color-purple-primary
			margin: 1px 0 -2px 0

		li .p-tabview-nav-link
			border: none
			color: $color-gray-darker
			font-family: 'Roboto-Bold', sans-serif
			height: 100%

		li.p-disabled .p-tabview-nav-link
			color: $color-gray-light-dark
			border: none

		li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link
			border: none
			color: $color-gray-darker
			background-color: #F8F9FB

		li .p-tabview-nav-link:not(.p-disabled):focus
			box-shadow: none