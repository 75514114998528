.k-card
    margin-bottom: 30px
    border-radius: 4px
    padding: 20px
    background-color: $color-white
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px

    .k-card-header
        display: flex
        justify-content: flex-start
        align-items: center
        margin-bottom: .5rem

        .k-card-title
            font-family: 'Roboto-Black', sans-serif
            font-size: 1.5rem
            color: $color-gray-darker
            padding-bottom: 5px
            margin-right: 2rem
            position: relative
            display: inline-block

            &::before
                position: absolute
                left: 0
                bottom: 0
                content: ""
                width: 30%
                height: 3px
                background-color: $color-purple-primary

            &.title-simple
                padding-bottom: 0
                &::before
                    background-color: transparent

    .k-card-content
        padding: 0
        border: none
