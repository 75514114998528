// Datepicker
.k-inputdate-container

  // Label (Calendar/Inline)
  &.is-inline .p-float-label label
    font-size: 14px
    top: 0.1rem
    left: 0.65rem
    padding: 0 4px
    background-color: $color-white
    border-radius: 2px

  // Input
  .p-calendar
    width: 100%

    .p-inputtext
      border-right: none

      &:enabled:focus + .p-datepicker-trigger
        border-width: 2px
        border-color: $color-purple-primary

    &:hover
      input:not(:disabled),
      input:not(:disabled) + .p-datepicker-trigger
        border-width: 2px

    // Calendar trigger button: disbaled
    input.p-disabled + .p-datepicker-trigger
      opacity: 0.65

    // Calendar trigger button: invalid
    &.p-invalid
      .p-datepicker-trigger,
      .p-datepicker-trigger:hover
        border-color: #dc3545

    // Calendar trigger button: default
    .p-datepicker-trigger
      color: $color-gray-medium
      background-color: $color-white
      border-color: $color-gray-medium
      border-left: none
      &:hover
        color: $color-purple-primary

  // Remove input right border
  .p-float-label input:not(:last-child)
    border-right: none
    border-top-right-radius: 0px
    border-bottom-right-radius: 0px

// Calendar panel
.p-datepicker
  &:not(.p-datepicker-inline),
  &.p-datepicker-inline
    padding: 0 10px
    border-color: $color-gray-medium

    // Header
    .p-datepicker-header
      // background-color: $color-gray-lighter
      background-color: $color-white
      color: $color-gray-darker

      .p-datepicker-title .p-datepicker-year,
      .p-datepicker-title .p-datepicker-month
        color: $color-gray-darker
        font-family: 'Roboto-Black', sans-serif
        font-size: 18px
        padding-top: 0
        padding-bottom: 0

        &:hover
          color: $color-purple-primary

      .p-datepicker-prev,
      .p-datepicker-next
        color: $color-gray-darker

        &:hover
          color: $color-purple-primary

    // Footer
    .p-datepicker-buttonbar
      padding: 0.5rem 0

      .p-button-today
        background-color: $color-purple-primary
        border: 1px solid $color-purple-primary
        color: $color-white

        &:enabled:hover
          background-color: $color-purple-darker
          border-color: $color-purple-darker
          box-shadow: none

      .p-button-clear
        color: $color-purple-primary
        border-color: $color-purple-primary

        &:enabled:hover
          color: $color-purple-darker
          background-color: transparent
          border-color: $color-purple-darker

    // Dates
    table

      // Week days
      th
        text-align: center
        span
          width: 2rem
          height: 2rem
          color: $color-gray-light-dark
          font-family: 'Roboto-Bold', sans-serif

      // Day cells
      td
        padding: 0.25rem

        span
          width: 2rem
          height: 2rem
          border-radius: 50%
          font-family: 'Roboto-Regular', sans-serif
          color: $color-gray-darker

          &:not(.p-highlight):not(.p-disabled):hover
            background-color: $color-purple-lightest

          &.p-disabled
            color: $color-gray-light-dark

          &.p-highlight
            color: $color-white
            background-color: $color-purple-primary

      // Today
      td.p-datepicker-today span
        position: relative
        // overflow: visible
        background-color: $color-white
        color: $color-gray-darker

        &::before
          position: absolute
          left: 20%
          bottom: 3px
          content: ""
          width: 60%
          height: 2px
          background-color: $color-purple-primary

        &.p-highlight
          background-color: $color-purple-primary
          color: $color-white

          &::before
            background-color: $color-white

  // Month & Year pickers
  .p-monthpicker .p-monthpicker-month,
  .p-yearpicker .p-yearpicker-year
    font-family: 'Roboto-Regular', sans-serif
    color: $color-gray-darker

    &:not(.p-disabled):not(.p-highlight):hover
      background-color: $color-purple-lightest !important

    &.p-disabled
      color: $color-gray-light-dark

    &.p-highlight
      color: $color-white
      background-color: $color-purple-primary

  // Time picker
  .p-timepicker
    font-family: 'Roboto-Regular', sans-serif
    button
      color: $color-gray-darker
      &:enabled:hover
        color: $color-purple-primary

  // Calendar opened inside a table (fix overflow issue)
  &.month-year-table
    width: 200px !important
