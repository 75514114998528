.sidebar-responsive
	.p-sidebar-header
		display: none

	.menu-option

		&.empty
			.p-panel-header
				padding: 1rem 0
			.p-panel-content
				padding: 0

		// Menu: first level
		.p-panel-header
			padding: 0.5rem 0
			border: none
			border-bottom: 1px solid $color-gray-lightest
			background-color: $color-white

			.menu-item
				color: $sidebar-item-color
				font-family: 'Roboto-Regular', sans-serif
				font-size: 1rem
				text-decoration: none

				&:hover, &:focus, &.active
					color: $sidebar-item-color-active
					text-decoration: none

			.p-panel-header-icon
				color: $sidebar-item-color

				&:hover, &:focus, &:active
					color: $sidebar-item-color-active
					box-shadow: none

		// Menu: nested level
		.p-panel-content
			border: none
			padding: 0
			margin-left: 2rem

			.menu-item
				display: flex
				align-items: center
				width: 100%
				color: $sidebar-item-color
				font-family: 'Roboto-Regular', sans-serif
				font-size: 1rem
				text-decoration: none
				border-bottom: 1px solid $color-gray-lightest
				padding: 1rem 0

				&:hover, &:focus, &:active, &.active
					color: $sidebar-item-color-active
					text-decoration: none

				.menu-icon
					margin-right: 1rem
					font-size: 1rem
