.participant_notes_form
	flex: 1
	margin-right: 2rem

	&__title
		font-family: "Roboto-Black", sans-serif
		font-size: 1.5rem
		color: $color-gray-darker
		margin-bottom: 1rem

	.custom-field
		display: flex
		align-items: flex-start
		width: 100%

		.label-wrapper
			margin-right: .5rem
			line-height: 1rem
		
		.field-label
			font-weight: bold
			font-size: 1.2rem
			margin-right: 4px

		.field-icon
			font-size: .75rem
			cursor: pointer
			vertical-align: top

		.input-wrapper
			flex: 1
