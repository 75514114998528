@import './variables/variables'
@import './components/components'
@import './pages/pages'
@import './fonts'

html
    height: 100%

body
    min-height: 100%
    height: 100%
    margin: 0
    font-family: 'Roboto-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-size: $body-font-size
    background-color: $body-bg

h1
    font-size: 32px
    font-family: 'WorkSans-Black', sans-serif
h2
    font-size: 26px
    font-family: 'WorkSans-Black', sans-serif
h3
    font-size: 22px
    font-family: 'WorkSans-Black', sans-serif
h4
    font-size: 18px
    font-family: 'Roboto-Black', sans-serif
h5
    font-size: 16px
    font-family: 'Roboto-Black', sans-serif
h6
    font-size: 14px
    font-family: 'Roboto-Black', sans-serif

.text-danger
    color: $color-red-primary !important

.text-success
    color: $color-green-darker !important

.text-success-darker
    color: $color-green-darkest !important

.text-purple
    color: $color-purple-primary !important

.text-warning
    color: $color-orange-primary !important

.text-gray
    color: $color-gray-light-dark !important

.text-yellow
    color: $color-yellow-primary !important

.text-black
    color: $color-gray-darker !important

.text-bold
    font-family: 'Roboto-Bold', sans-serif

.success-bg
    background-color: $color-green-lighter
