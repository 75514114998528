.k-header-responsive
	height: $header-height
	background-color: $header-bg-color
	box-shadow: $header-box-shadow
	position: fixed
	top: 0
	left: 0
	width: 100%
	z-index: 999

	.header-container
		display: flex
		justify-content: space-between
		align-items: center
		padding: 0 2rem

		.header-logo
			width: auto
			height: $header-height

			img
				height: 100%
				max-width: 100%
				color: $color-purple-primary

		// Quick action: menu
		.quick-actions-panel
			margin-left: 0
			position: absolute
			background-color: $color-white
			box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px
			border-radius: 4px
			padding: 1rem
			right: 2rem
			top: 4rem
			min-width: 15rem
			display: none
			animation: scalein .15s linear

			&.active
				display: block

			.quick-action
				&:not(:last-child)
					margin-bottom: 1rem

			// Switch: admin/coach view
			.switch-view
				display: flex
				align-items: center

				.view-mode
					margin: 0 10px
					color: $header-item-color
					font-family: 'Roboto-Bold', sans-serif
					font-size: 16px

					&.current
						color: $header-item-color-active

				.p-inputswitch
					width: 3rem
					height: 1.75rem

					&.p-inputswitch-checked
						.p-inputswitch-slider
							background-color: $header-item-color-active

					.p-inputswitch-slider
						background-color: $header-item-color-active
						border: .5px solid $header-item-color
						border-radius: 30px

						&:before
							border-radius: 50%

			// Phone call
			.call-responsive
				color: $header-item-color
				background-color: transparent
				font-family: 'Roboto-Bold', sans-serif
				font-size: 1rem
				border: none
				padding: 0

				.p-button-icon-left
					font-size: 1.5rem
					margin-right: 1rem

				&:hover, &:focus
					color: $header-item-color-active
					background-color: transparent
					border: none

				&.active
					color: $header-item-color-active
					background-color: transparent
					border: none

			// Logout
			.header-logout
				display: inline-flex
				justify-content: center
				align-items: center
				position: relative
				color: $header-item-color
				font-family: 'Roboto-Bold', sans-serif
				font-size: 1rem
				cursor: pointer
				transition: border-color .2s, color .2s
				text-decoration: none

				&:hover, &:focus
					color: $header-item-color-active
					background-color: transparent

				&.active
					color: $header-item-color-active
					background-color: transparent

				.logout-icon
					font-size: 1.5rem
					margin-right: 1rem
