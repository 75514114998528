.p-datatable.medications-table
	.p-datatable-tbody
		td
			.frequency__input
				padding-right: 10px
				width: 100px

			.unit__input
				width: 100px

			.dosage__textarea
				width: 100px

