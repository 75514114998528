// .p-tooltip
//     &.p-tooltip-left,
//     &.p-tooltip-top,
//     &.p-tooltip-right,
//     &.p-tooltip-bottom
//         .p-tooltip-arrow
//             border-color: $color-purple-lightest
//     .p-tooltip-text
//         background: $color-purple-lightest
//         border-radius: 4px
//         border: .5px solid rgba(195, 156, 244, 0.5) // $color-purple-light
//         color: $color-purple-primary

.p-tooltip
    // &.p-tooltip-left,
    // &.p-tooltip-top,
    // &.p-tooltip-right,
    &.p-tooltip-bottom
        .p-tooltip-arrow
            // border-color: $color-gray-darker
    .p-tooltip-text
        background: $color-gray-darker
        border-radius: 4px
        border: .5px solid $color-gray-darker
        color: $color-white
