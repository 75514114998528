.participant-profile
	display: flex
	align-items: center

.participant-profile .primary-info
	display: flex
	flex-direction: column
	justify-content: center
	align-items: flex-start
	flex-wrap: wrap
	min-width: 50%

.participant-profile .primary-info__first
	display: flex
	align-items: center
	margin-bottom: .5rem
	width: 100%

.participant-profile .name
	font-family: 'Roboto-Black', sans-serif
	font-size: 1.5rem
	color: $color-gray-darker
	margin: 0 5px 0 0
	padding-top: 8px

.participant-profile .pronouns
	font-family: 'Roboto-Black', sans-serif
	font-size: 1.5rem
	color: $color-gray-darker
	font-style: italic
	padding-top: 8px

.participant-profile .logo
	max-width: 100%
	max-height: 35px
	height: auto
	margin-left: 2rem

.participant-profile .primary-info__second
	display: flex
	align-items: center
	flex-wrap: wrap

	& > *
		margin: .5rem

	& > :first-child
		margin-left: 0

.participant-profile .secondary-info
	display: flex
	align-items: flex-start
	justify-content: flex-end
	min-width: 50%
	font-family: "Roboto-Regular", sans-serif
	font-size: 14px
	color: $color-gray-darker
	word-break: break-word

	.icon-edit
		cursor: pointer
		fill: $color-gray-darker
		padding-left: 10px
		font-size: 24px

@include media-breakpoint-down(xl)
	.participant-profile .primary-info
		min-width: 50%

@include media-breakpoint-down(lg)
	.participant-profile
		display: block

@include media-breakpoint-down(md)
	.participant-profile
		flex-direction: column

	.participant-profile .primary-info
		min-width: 100%

	.participant-profile .primary-info__first
		margin-bottom: 0

	.participant-profile .secondary-info
		margin-top: 1rem

@include media-breakpoint-down(sm)
	.participant-profile .primary-info__first
		display: block

	.participant-profile .name
		display: inline-block

	.participant-profile .pronouns
		display: inline-block

	.participant-profile .logo
		display: block
		margin-left: 0
	
	.participant-profile .primary-info__second
		& > *
			margin-left: 0
			
		.k-phone-caller-container
			justify-content: flex-start

	.participant-profile .pronouns
		margin: 0
	