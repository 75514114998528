.showcases-container
	height: calc(100vh - $header-height)

	.form-info
		.info-text
			color: $color-gray-light-dark
			font-family: 'Roboto-Regular', sans-serif
			font-size: 16px
			margin-right: 10px
		.info-icon
			font-size: 16px
			&.success
				color: $color-green-primary
			&.error
				color: $color-red-primary

		.form-values-list
			display: flex
			flex-wrap: wrap
			list-style: none
			padding: 0
			margin: 0

			.list-item
				margin-right: 15px
				.field
					margin-right: 5px

	.button-showcase
		button
			margin: .5rem
