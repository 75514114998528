.service-utilization-goals-container

  .tab-count
    margin-left: 0.5rem

  .empty-category
    font-family: 'Roboto-Regular', sans-serif
    color: $color-gray-darker
    font-size: 1rem

  .sug-card
    margin-bottom: 2rem
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px

    .p-card-header
      padding: 0.25rem 1rem
      display: flex
      justify-content: space-between
      align-items: center
      // background-color: #ededed
      background-color: #eff3f8

      .sug-title
        font-family: 'Roboto-Bold', sans-serif
        font-size: 1.25rem
        color: $color-gray-darker

      .sug-status-container
        .sug-status
          display: flex
          align-items: center

          &.on_track
            color: $color-green-dark
          &.no_data
            color: $color-orange-primary
          &.due_soon,
          &.therapy_due_soon,
          &.survey_due_soon
            color: $color-yellow-primary
          &.off_track,
          &.therapy_off_track,
          &.survey_off_track,
          &.never_received
            color: $color-red-primary
          &.fallback
            color: $color-gray-light-dark

          .status-title
            font-family: 'Roboto-Bold', sans-serif
            font-size: 1.25rem

          .status-date
            font-family: 'Roboto-Bold', sans-serif
            font-size: 1.25rem
            margin-left: .5rem

          .status-icon
            font-size: 1.25rem
            margin-left: .5rem

    .p-card-body
      padding: 0

      .p-card-content
        padding: 0
        display: flex

      .sug-form,
      .sug-history
        width: 50%

      .sug-form
        border-right: 1px solid $color-gray-lightest

      .sug-content-header
        padding: 0.5rem 1rem
        font-family: 'Roboto-Bold', sans-serif
        font-size: 1rem
        color: $color-gray-darker
        border-bottom: 1px solid $color-gray-lightest

      .sug-form-content
        padding: 1rem

.sug-history__no-content-container
  .k-empty-message
    padding: 1rem 0.5rem !important

.sug-history__no-content-cta-wrapper
  display: flex
  justify-content: center
  padding-bottom: 2rem
  background-color: $color-white
