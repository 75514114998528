.pt-details-card

	.details-header
		font-family: 'Roboto-Bold', sans-serif
		font-size: 1.125rem
		color: $color-gray-darker
		padding: .5rem 0

	.fields-section-title
		font-family: 'Roboto-Bold', sans-serif
		font-size: 1.125rem
		color: $color-gray-darker
		margin-bottom: .5rem

.pt-details-field
	margin-bottom: 1rem

	.field-title
		font-family: 'Roboto-Bold', sans-serif
		font-size: 1rem
		margin-bottom: .35rem

	.field-value
		font-family: 'Roboto-Regular', sans-serif
		font-size: 1rem
		word-break: break-all
