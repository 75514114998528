.p-checkbox
    
    &:not(.p-checkbox-disabled)
        .p-checkbox-box
            &.p-highlight:hover
                background: $color-purple-darker
                border-color: $color-purple-darker
            &:hover
                border-color: $color-gray-darker
            &.p-focus
                box-shadow: none
                border-color: $color-gray-darker

    .p-checkbox-box
        border: 1px solid $color-gray-darker

        &.p-highlight
            background: $color-purple-primary
            border-color: $color-purple-primary
        