.hedis-goals_overlaypanel
	--overlayArrowLeft: 240px!important
	min-width: 300px
	font-size: 0.8rem

	.p-overlaypanel-content
		padding: 0.5rem

	.hedis-goals__container
		.hedis-goals__section:not(:last-child)
			margin-bottom: 1rem

		.hedis-goals__title
			font-weight: 600

		.hedis-goals__list
			padding-left: 1rem
			margin: 0

.hedis-goals__circle
	width: 1.2rem
	height: 1.2rem
	line-height: 1.2rem
	min-width: 1.2rem
	margin-left: 5px
	margin-right: 5px

.hedis-goals__badge-content
	font-size: 10px

.hedis-goals_button-no-focus
	margin-left: 5px
	&:focus
		outline: 0
		box-shadow: none
