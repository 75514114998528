// Textarea
.p-inputtextarea.p-inputtext
	height: auto

.p-float-label textarea ~ label
	top: 1.4rem

.p-input-icon-left > i,
.p-input-icon-right > i
	top: 1.5rem
