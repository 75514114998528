.k-select-multiple-container
	.p-multiselect
		width: 100%

	.input-icon
		z-index: 50

	.input-icon + .p-multiselect .p-multiselect-label
		padding-left: 2.5rem

// Select styling (including selects that are inside other components: table pagintor, etc.)
.p-inputgroup > .p-float-label > .p-component
	border-radius: 4px

.p-multiselect
	width: 100%
	border-radius: 4px
	border-color: $color-gray-medium
	color: $color-gray-darker
	height: 48px

	.p-multiselect-label
		color: $color-gray-darker
		height: 46px // 46px + 1px (border-top) + 1px (border-bottom) = 48px (height of all form components)
		padding-top: 10px

	.p-multiselect-trigger
		color: $color-gray-medium

	&:not(.p-disabled):hover
		border-width: 2px
		border-color: $color-gray-medium

		.p-multiselect-clear-icon:hover
			color: $color-purple-primary
		.p-multiselect-trigger:hover
			color: $color-purple-primary

	&:not(.p-disabled).p-focus
		border-width: 2px
		border-color: $color-purple-primary
		box-shadow: none

		.p-multiselect-clear-icon
			color: $color-purple-primary
		.p-multiselect-trigger
			color: $color-purple-primary

// Select options container
.p-multiselect-panel
	border-radius: 4px
	z-index: 99999 !important

	// Filter options
	.p-multiselect-header
		background-color: $color-gray-lighter

		.p-multiselect-filter-container

			// Clearable filter
			&.p-multiselect-clearable-filter
				.p-multiselect-filter
					padding-right: 2.5rem

			.p-multiselect-filter
				padding-left: 2.5rem

			.p-multiselect-filter-icon
				color: $color-gray-medium
				left: 0.75rem
				width: fit-content

			.p-multiselect-filter-clear-icon
				color: $color-gray-medium
				right: 0.75rem
				width: fit-content
				&:hover
					color: $color-purple-primary
					cursor: pointer

	// Options list
	.p-multiselect-items
		padding: 0

		.p-multiselect-item
			color: $color-gray-darker
			background-color: $color-white

			&:first-child
				border-top-left-radius: 4px
				border-top-right-radius: 4px
			&:last-child
				border-bottom-left-radius: 4px
				border-bottom-right-radius: 4px

			&:not(.p-highlight):not(.p-disabled):hover
				background-color: $color-purple-lightest
				color: $color-purple-primary

			&.p-highlight
				background-color: $color-purple-lightest
				color: $color-purple-primary
