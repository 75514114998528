.k-table.p-datatable.DEFAULT

	/* HEADER */

	.table-title
		color: $color-gray-darker

	/* HEAD */

	// Head: Columns
	.p-datatable-thead > tr > th
		color: $color-gray-light-dark
		background-color: $color-white
		border: 1px solid $color-gray-lightest
		border-width: 1px 0 2px
		padding: 1rem
		text-align: left

	// Head: Columns: sortable
	.p-sortable-column

		// hover, Focus, Active, Active+Hover
		&.p-highlight,
		&.p-highlight:not(.p-sortable-disabled):hover,
		&:not(.p-sortable-disabled):hover,
		&:not(.p-highlight):not(.p-sortable-disabled):hover,
		&:focus
			color: $color-green-darkest
			background-color: $color-white

			.p-sortable-column-icon
				color: $color-green-darkest

		.p-sortable-column-icon
			color: $color-gray-light-dark


	/* BODY */
	
	// Body: Rows: hover
	&.p-datatable-hoverable-rows .p-datatable-tbody > tr
		&:not(.p-highlight):hover,
		&:not(.p-datatable-emptymessage):hover,
		&:not(.row-inactive):hover
			background-color: $color-gray-background2
			color: $color-gray-darker

	// Body: Message
	.p-datatable-tbody > tr.p-datatable-emptymessage
		.k-empty-message
			background-color: $color-white

			.k-field-message,
			.k-field-icon
				color: $color-gray-light-dark

			&.loading-message
				background-color: $color-gray-lighter

				.k-field-message,
				.k-field-icon
					color: $color-gray-light-dark

	// Body: Rows
	.p-datatable-tbody > tr
		color: $color-gray-darker
		background-color: $color-white

	// Body: Rows: inactive
	.p-datatable-tbody > tr.row-inactive
		background-color: $color-gray-lighter

	// Body: Rows: Cells
	.p-datatable-tbody > tr > td
		border-top: none
		border-bottom: 1px solid $color-gray-lightest


	/* PAGINATOR */
		
	.p-paginator.p-paginator-bottom
		border: none

	.footer-current-page
		color: $color-gray-darker

	.footer-rpp-text
		color: $color-gray-darker

	.p-paginator
		.p-paginator-first,
		.p-paginator-prev,
		.p-paginator-next,
		.p-paginator-last
			background-color: transparent
			border-color: transparent
			color: $color-gray-light-dark

			&:hover,
			&:not(.p-disabled):not(.p-highlight):hover
				background-color: $color-purple-lightest
				border-color: $color-purple-lightest
				color: $color-purple-primary

			&.p-disabled
				background-color: transparent
				border-color: transparent
				color: $color-gray-light

	.p-paginator .p-paginator-pages	.p-paginator-page
		background-color: transparent
		border-color: transparent
		color: $color-gray-light-dark

		&:not(.p-highlight):hover
			background-color: $color-purple-lightest
			border-color: $color-purple-lightest
			color: $color-purple-primary

		&.p-highlight
			background-color: $color-purple-lightest
			border-color: $color-purple-lightest
			color: $color-purple-primary