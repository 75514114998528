.messages-wrapper
	border-radius: 4px
	background-color: $color-white

	.messages-info-responsive
		display: flex
		justify-content: flex-end
		align-items: center
		margin-bottom: 5px

	.messages-container
		padding: 20px
		background-color: #f5f7ff
		border-radius: 15px
		border: 1px solid #eaeeff

		.messages-history
			height: 240px
			overflow-y: auto
			padding-top: 10px

			.message-container
				margin-bottom: 10px
				border-radius: 15px
				background-color: #eceefc

				&.own-message
					margin-left: 20%
					border-top-right-radius: 0

				&.other-message
					margin-right: 20%
					border-top-left-radius: 0

				.message-wrapper
					padding: 5px 10px

					.message-info
						display: flex
						justify-content: space-between
						align-items: center

						.message-author
							font-family: 'Roboto-Bold', sans-serif

						.message-date
							font-family: 'Roboto-Regular', sans-serif
							font-size: 0.75rem
							color: $color-gray-light-dark

					.message-body
						overflow-wrap: break-word

		.messages-write
			display: flex
			align-items: center
			padding-top: 20px
			margin-bottom: auto

			.k-input-container
				width: 100%
				margin: 0 10px 0 0

.conversation-menu
	.conversation-menu-badge
		font-size: 0.75rem
		min-width: 1rem
		height: 1rem
		line-height: 1rem

.messages__unread-badge
	margin-left: 6px
	font-size: 0.75rem
	min-width: 20px
	height: 20px
	line-height: 20px
