.table-filters-container
	background-color: $color-white
	padding: 0 $table-cell-padding 1rem

	.filters-title
		font-family: 'Roboto-Bold', sans-serif
		font-size: 1rem
		color: $color-gray-light-dark
		margin-right: 1rem

	// Filters form
	form
		display: grid
		grid-template-columns: 1fr auto
		align-items: center
		column-gap: 10px
		justify-content: flex-end

		.filters-form-container
			min-width: 0 // Very important to prevent the overflow problem when selecting all the filter options

		.filters-form
			display: flex
			justify-content: flex-end
			align-items: center
			flex-wrap: wrap
			flex: 1
			
			.filter-col
				&:not(:first-child)
					margin: .5rem

			.k-input-container
				margin-bottom: 0

			// Template: select multiple
			.p-multiselect, .p-dropdown
				height: 36px
				border-radius: 2rem

				.p-multiselect-label, .p-dropdown-label
					padding-top: 6px

		.filters-buttons
			padding-left: 10px
			min-width: 150px

			button
				font-size: 14px
				padding: 0.4rem 0.75rem

				&:not(:last-child)
					margin-right: 10px

.table-header-filters--dates
	display: flex
	justify-content: flex-start
	align-items: center

	.k-input-container
		margin: 0
		width: 100%

	.p-calendar
		.p-inputtext
			height: 36px
			border-top-left-radius: 2rem
			border-bottom-left-radius: 2rem

		.p-button.p-button-icon-only
			font-size: 14px
			height: 36px
			border-top-right-radius: 2rem
			border-bottom-right-radius: 2rem

.table-header-filters--boolean
	.k-inputcheckbox-container.chbx-binary
		padding-top: 0