.k-sidebar-container
    background-color: $sidebar-bg-color
    box-shadow: $sidebar-box-shadow
    border: 1px solid transparent

    position: fixed
    width: $sidebar-width
    height: 100vh
    padding: 0 0 4rem 0
    z-index: 500
    overflow-y: auto
    -webkit-user-select: none
    user-select: none
    top: $header-height
    left: 0
    transition: width .2s

    &.active
        transform: translate3d(0px, 0px, 0px)
        width: $sidebar-width-expanded

        .sidebar-toggle
            margin-left: 1rem

        .menu-item
            justify-content: flex-start
            padding-left: 5px

        .menu-title
            display: inline-block

    .sidebar-toggle
        font-size: 1.2rem
        fill: $color-purple-primary
        margin: 1rem auto
        display: block
        max-width: 100%

        &:hover, &:focus, &.active
            cursor: pointer
            fill: $sidebar-item-color-active

    .menu-list
        list-style: none
        padding: 0
        margin: 0
        flex: 1

    .menu-item
        padding: 1rem 0
        color: $sidebar-item-color
        font-family: 'Roboto-Regular', sans-serif
        font-size: 16px
        text-decoration: none
        display: flex
        align-items: center
        justify-content: center
        overflow: hidden
        min-height: $sidebar-item-height

        &:hover, &:focus, &.active
            color: $sidebar-item-color-active
            border-right: 3px solid $sidebar-item-color-active

    .menu-icon
        font-size: 1.25rem
        padding: 0 15px

    .menu-title
        white-space: nowrap
        display: none
