.participant-notes-history
	&__title
		font-family: "Roboto-Black", sans-serif
		font-size: 1.5rem
		color: $color-gray-darker
		margin-bottom: 1rem

	&__filter 
		.k-select-multiple-container .p-multiselect
			height: 40px
		.p-multiselect .p-multiselect-label
			height: 40px
			padding: 0.5rem 0.75rem

	&__panel-header
		display: flex
		flex-direction: row
		align-items: center

		> *:not(:last-child)
			margin-right: 4rem

	&__panel
		&:not(:last-child)
			margin-bottom: 1rem

	.p-panel-content
		padding: 0

	&__panel-content
		padding: 1.25rem

		&--editing
			background-color: #A9A9A9,
			opacity: 0.4,
			pointer-events: none

	&__panel-item
		overflow-wrap: break-word

		&:not(:last-child)
			margin-bottom: 0.25rem

		&:last-child
			margin-bottom: 0

		&-label
			font-weight: bold

		&-label-goals
			font-weight: bold
			vertical-align: top

	&__panel-goals
		display: inline-block

		ul
			padding-left: 1rem

@include media-breakpoint-down(md)
	.participant-notes-history
		&__filter 
			margin-bottom: 1rem